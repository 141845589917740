/*
 * Documentation:
 * Mark as Modal — https://app.subframe.com/library?component=Mark+as+Modal_da3316f7-77d7-4265-8efb-09e23378b09d
 * Icon Button — https://app.subframe.com/library?component=Icon+Button_af9405b1-8c54-4e01-9786-5aad308224f6
 * Radio Group — https://app.subframe.com/library?component=Radio+Group_c4b6300e-20b4-4f3e-8b9f-379a046674ca
 * Multiline Input — https://app.subframe.com/library?component=Multiline+Input_4ec05ee8-8f1c-46b2-b863-5419aa7f5cce
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Dialog — https://app.subframe.com/library?component=Dialog_ca59db17-43fb-4247-8094-3c55162e902d
 */

import { TextArea } from 'subframe/components/TextArea';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import styles from './MarkAsIgnoreDialog.module.scss';
import { SetStateFunction } from 'models/react';
import { Cluster, LARIgnore, UpdateLARStatusRequest } from 'api/models';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';
import { IconButton, RadioGroup } from 'subframe/index';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormProvider from 'components/FormProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { updateLARStatus } from 'api/frontend';
import { LarIgnoreReason } from 'api/models/larIgnoreReason';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import { markAsReasonDisplay } from '../models/markAs';
import { Text } from '@subframe/core';
import { AtomicTooltip } from 'components/design-system';
import { ExtendedLAR } from '..';
import {
  charLimitExceededErrorMessage,
  charLimits,
  fieldIsRequiredErrorMessage,
} from 'constants/input-validation';

interface MarkAsIgnoreDialogProps {
  lar: ExtendedLAR;
  cluster: Cluster;
  handleOpen: SetStateFunction<boolean>;
  isOpen: boolean;
  ignoreStatus?: LARIgnore;
  mutateAffClusters: SetStateFunction<boolean>;
  mutateStatus: () => void;
}

function MarkAsIgnoreDialog({
  lar,
  cluster,
  handleOpen,
  isOpen,
  mutateStatus,
  ignoreStatus,
  mutateAffClusters,
}: MarkAsIgnoreDialogProps) {
  const { account } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const [markAs, setMarkAs] = useState<LarIgnoreReason | ''>(
    ignoreStatus?.reason || '',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ignoreReasonSchema = Yup.object().shape({
    comment: Yup.string()
      .optional()
      .max(
        charLimits.ActionDetails,
        charLimitExceededErrorMessage('Comments', charLimits.ActionDetails),
      ),
    buttonType: Yup.string()
      .oneOf(['mark', 'unmark', 'markAndIgnore', 'updateComment'])
      .required(),
    markAs: Yup.string()
      .oneOf([...Object.values(LarIgnoreReason)], 'Select a reason')
      .nullable()
      .when('buttonType', {
        is: (value: string) => value === 'mark' || value === 'markAndIgnore',
        then: Yup.string().required(fieldIsRequiredErrorMessage('Reason')),
      }),
  });

  const defaultValues = {
    markAs: ignoreStatus?.reason || '',
    comment: ignoreStatus?.comment || '',
    buttonType: '',
  };

  const methods = useForm({
    resolver: yupResolver(ignoreReasonSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    reset,
    clearErrors,
  } = methods;

  const onSubmit = async (data: {
    comment: string;
    markAs: string;
    buttonType: string;
  }) => {
    const unMarkButtonClick = data.buttonType === 'unmark';
    const markAndIgnoreButtonClick = data.buttonType === 'markAndIgnore';
    const updateCommentButtonClick = data.buttonType === 'updateComment';

    setIsLoading(true);

    if (!markAs && !unMarkButtonClick) {
      resetToDefault();
      setIsLoading(false);
      return;
    }

    try {
      let markAsIgnoreLARRequest: UpdateLARStatusRequest;
      if (unMarkButtonClick) {
        // On Unmark Click reset everything in ignore_status and unignore the ignore state.
        markAsIgnoreLARRequest = {
          ignore_status: {
            status: 'unignored',
          },
        };
      } else {
        // Else its Mark, so update the Reason with the new Reason and/or comment value
        markAsIgnoreLARRequest = {
          ignore_status: {
            status: markAndIgnoreButtonClick
              ? 'ignored'
              : ignoreStatus?.status || 'unignored',
            reason: markAs as LarIgnoreReason,
            comment: data?.comment,
          },
        };
      }

      if (markAs === 'FalsePositive') {
        markAsIgnoreLARRequest.false_positive_status = unMarkButtonClick //Should udpate the False Positive field Status when marking and unmarking
          ? 'in-validation-recovered'
          : 'reported';
      }

      const markAsIgnoreResp = await updateLARStatus(
        cluster.id,
        lar.signature_id,
        markAsIgnoreLARRequest,
        {
          headers: { Authorization: `Bearer ${account?.token}` },
        },
      );

      if (markAsIgnoreResp) {
        mutateStatus(); //for ignore button state update
        (markAndIgnoreButtonClick || unMarkButtonClick) &&
          mutateAffClusters(true); // for affected clusters update in case the risk was ignored or unignored(unmarked)

        logEvent(
          `${
            unMarkButtonClick
              ? 'unmark'
              : updateCommentButtonClick
              ? 'update-comment-mark'
              : 'mark'
          }${markAndIgnoreButtonClick ? '-ignore-AR' : '-AR'}`,
          {
            markAsIgnoreResp,
          },
        );

        if (updateCommentButtonClick) {
          enqueueSnackbar(`Comment was updated successfully`, {
            variant: 'success',
            autoHideDuration: toastAutoHideDuration,
          });
        } else {
          enqueueSnackbar(
            unMarkButtonClick
              ? `Operational Risk was unmarked successfully`
              : `Operational Risk was marked as ${
                  markAsReasonDisplay[markAs as LarIgnoreReason]
                } ${
                  markAndIgnoreButtonClick ? 'and ignored ' : ''
                }successfully`,
            {
              variant: 'success',
              autoHideDuration: toastAutoHideDuration,
            },
          );
        }
        setIsLoading(false);
      }
      handleOpen(false);
    } catch (e) {
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'ignoring this Risk'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(e);
    } finally {
      resetToDefault();
      setIsLoading(false);
    }
  };

  const resetToDefault = () => {
    reset(defaultValues);
    setMarkAs(ignoreStatus?.reason || '');
  };

  if (ignoreStatus?.reason) {
    setValue('markAs', ignoreStatus.reason);
  }

  useEffect(() => {
    setValue('markAs', ignoreStatus?.reason || '');
    setValue('comment', ignoreStatus?.comment || '');
    setMarkAs(ignoreStatus?.reason || '');
  }, [ignoreStatus]);

  const isDisabled = ignoreStatus?.reason != undefined;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        clearErrors();
        resetToDefault();
        handleOpen(!isOpen);
      }}
      onChange={() => {
        //needed else it sets markAs to null}
        if (!isOpen) {
          resetToDefault();
        }
      }}
    >
      <Dialog.Content>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['stack-5180a9c2']}>
            <div className={styles['stack']}>
              <div className={styles['stack-b4b433e1']}>
                <span className={styles['text-bcb79cc7']}>
                  {ignoreStatus?.reason ? 'Unmark' : 'Mark as'}
                </span>
                <IconButton
                  icon="FeatherX"
                  size="medium"
                  onClick={() => {
                    clearErrors();
                    resetToDefault();
                    handleOpen(!isOpen);
                  }}
                />
              </div>
            </div>
            <div className={styles['stack-1821e0cc']}>
              <div className={styles['stack-317a44ed']}>
                <div className={styles['stack-3bc7fb02']}>
                  <AtomicTooltip
                    tooltipContent={
                      ignoreStatus?.reason == undefined
                        ? undefined
                        : `To update your selection, unmark the Operational Risk first.`
                    }
                  >
                    <RadioGroup
                      className="h-auto w-full flex-none"
                      {...methods.register('markAs')}
                      defaultValue={defaultValues.markAs || ''}
                      onValueChange={(val: string) => {
                        setValue('markAs', val as LarIgnoreReason, {
                          shouldValidate: true,
                        });
                      }}
                    >
                      <div className="flex w-full items-start gap-4">
                        <RadioGroup.Option
                          label={markAsReasonDisplay['FalsePositive']}
                          checked={markAs === 'FalsePositive'}
                          onClick={() => setMarkAs('FalsePositive')}
                          value={'FalsePositive'}
                          disabled={isDisabled}
                        />
                        <RadioGroup.Option
                          label={markAsReasonDisplay['ByDesign']}
                          checked={markAs === 'ByDesign'}
                          onClick={() => setMarkAs('ByDesign')}
                          value={'ByDesign'}
                          disabled={isDisabled}
                        />
                        <RadioGroup.Option
                          label={markAsReasonDisplay['DevTestCluster']}
                          value={'DevTestCluster'}
                          checked={markAs === 'DevTestCluster'}
                          onClick={() => setMarkAs('DevTestCluster')}
                          disabled={isDisabled}
                          style={{
                            borderColor: isDisabled
                              ? 'var(--gray-100) !important'
                              : '',
                          }}
                        />
                        <RadioGroup.Option
                          label={markAsReasonDisplay['Other']}
                          value="Other"
                          checked={markAs === 'Other'}
                          onClick={() => setMarkAs('Other')}
                          disabled={isDisabled}
                        />
                      </div>
                    </RadioGroup>
                  </AtomicTooltip>
                </div>
                {errors?.markAs && (
                  <Text style={{ color: 'var(--error-800)' }}>
                    {errors?.markAs?.message}
                  </Text>
                )}
              </div>
              <AtomicTooltip
                tooltipContent={
                  ignoreStatus?.reason == undefined
                    ? undefined
                    : `To ${
                        ignoreStatus?.comment ? 'update your' : 'add a new'
                      } comment, unmark the Operational Risk first.`
                }
              >
                <TextArea
                  className="h-auto w-full flex-[0_0_auto]"
                  label={`Comments ${ignoreStatus?.reason ? '' : '(optional)'}`}
                  helpText={errors.comment?.message}
                  error={methods.getFieldState('comment').invalid}
                >
                  <TextArea.Input
                    className="h-auto w-full flex-[0_0_auto]"
                    placeholder={
                      getValues('comment') || ignoreStatus?.reason
                        ? ''
                        : 'Are there any additional details you would like to share?'
                    }
                    disabled={isDisabled}
                    {...methods.register('comment')}
                  />
                </TextArea>
              </AtomicTooltip>
            </div>
            <div className={styles['stack-8095e6b5']}>
              {!ignoreStatus?.reason ? (
                <div className={styles['stack-8095e6b5']}>
                  <Button
                    size="large"
                    type="submit"
                    variant="brand-secondary"
                    icon={null}
                    disabled={isSubmitting || isLoading}
                    loading={isSubmitting || isLoading}
                    onClick={() => {
                      setValue('buttonType', 'markAndIgnore');
                      setValue('markAs', markAs); //needed, else it sets markAs  value to null
                    }}
                    value={'markAndIgnore'}
                  >
                    Mark & Ignore
                  </Button>

                  <Button
                    size="large"
                    type="submit"
                    variant="brand-primary"
                    icon={null}
                    onClick={() => {
                      setValue('buttonType', 'mark');
                      setValue('markAs', markAs); //needed, else it sets markAs value to null
                    }}
                    disabled={isSubmitting || isLoading}
                    loading={isSubmitting || isLoading}
                  >
                    Mark
                  </Button>
                </div>
              ) : (
                <>
                  <Button
                    size="large"
                    type="submit"
                    variant="brand-primary"
                    icon={null}
                    disabled={isSubmitting || isLoading}
                    loading={isSubmitting || isLoading}
                    onClick={() => setValue('buttonType', 'unmark')}
                    value={'unmark'}
                  >
                    Unmark
                  </Button>
                </>
              )}
            </div>
          </div>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}

export default MarkAsIgnoreDialog;
