import { useParams } from 'react-router';

import {
  ARSigsSeverityCounts,
  convertToLowerCaseWithDashes,
  getNeedAttentionTooltip,
} from 'utils/filters';
import { ARSigCategoryCard } from 'components/ARSigCategoryCard';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { ClusterDetectedLar } from 'api/models';
import { RiskCard, SkeletonText, Tooltip } from 'subframe/index';
import * as SubframeCore from '@subframe/core';
import { RouterLink } from 'components/RouterLink';
import Iconify from 'components/Iconify';
import { useStatsigClient } from '@statsig/react-bindings';

type ARSigData = {
  UnignoredLARsCount: number;
  Critical: number;
  High: number;
  Medium: number;
  Low: number;
  NACount: number;
};

type ARSigOverviewCardProps = {
  title: string;
  type: string;
  icon: string;
  totalCount?: number;
  data?: ARSigData;
  hideSeverity?: boolean;
  loading?: boolean;
};

type ARSigOveriewProps = {
  DefectsCount?: number;
  MisconfigurationsCount?: number;
  SystemRequirementsCount?: number;
  DeprecationsCount?: number;
  UnsupportedVersionsCount?: number;
  VersionIncompatibilitiesCount?: number;
  BestPracticesCount?: number;
  UnignoredDefects?: ClusterDetectedLar[];
  UnignoredMisconfigurations?: ClusterDetectedLar[];
  UnignoredSystemRequirements?: ClusterDetectedLar[];
  UnignoredDeprecations?: ClusterDetectedLar[];
  UnignoredUnsupportedVersions?: ClusterDetectedLar[];
  UnignoredVersionIncompatibilities?: ClusterDetectedLar[];
  UnignoredBestPractices?: ClusterDetectedLar[];
  loading?: boolean;
};

function ARSigsOverviewCard({
  title,
  type,
  icon,
  totalCount,
  data,
  hideSeverity,
  loading,
}: ARSigOverviewCardProps) {
  let { clusterId } = useParams<{ clusterId: string }>();
  const linkTo = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/${clusterId}?view=${type}`,
  );

  const { RiskPopoverData } = ARSigCategoryCard;
  const isLoading = loading || data === undefined || totalCount === undefined;

  return (
    <RouterLink to={linkTo}>
      <RiskCard
        id={`${convertToLowerCaseWithDashes(title)}-category-card`}
        className="h-44 w-96 flex-none "
        icon={null}
        title={
          <div className="flex flex-row gap-2 items-center">
            <Iconify icon={icon} />
            <span>{title}</span>
          </div>
        }
        needAttentionTooltip={
          type !== 'guardrails' ? (
            <RiskPopoverData
              title={getNeedAttentionTooltip(type)}
              detailedCounts={!hideSeverity}
              critical={data?.Critical.toString()}
              high={data?.High.toString()}
              medium={data?.Medium.toString()}
              low={data?.Low.toString()}
              ignored={(
                (totalCount || 0) - (data?.UnignoredLARsCount || 0)
              ).toString()}
              total={data?.UnignoredLARsCount.toString()}
            />
          ) : undefined
        }
        numRisks={
          loading ? (
            <SkeletonText
              data-cy="loading-count"
              className="h-[32px] w-[50px]"
            />
          ) : (
            <span
              id={`${convertToLowerCaseWithDashes(title)}-category-card-count`}
            >
              {type === 'guardrails'
                ? data?.UnignoredLARsCount?.toString()
                : data?.NACount?.toString()}
            </span>
          )
        }
        needAttention={
          <>
            <span className="text-body font-body text-subtext-color">
              {type === 'guardrails' ? 'Not Followed' : 'Need Attention'}
            </span>
            {type !== 'guardrails' && (
              <SubframeCore.HoverCard.Root>
                <SubframeCore.HoverCard.Trigger asChild={true}>
                  <SubframeCore.Icon
                    className="text-body font-body text-subtext-color"
                    name="FeatherInfo"
                    data-cy={`${convertToLowerCaseWithDashes(
                      title,
                    )}-need-attention-icon`}
                  />
                </SubframeCore.HoverCard.Trigger>
                <SubframeCore.HoverCard.Portal>
                  <SubframeCore.HoverCard.Content
                    side="bottom"
                    align="center"
                    sideOffset={8}
                    asChild={true}
                  >
                    <div
                      className="flex flex-col items-start gap-1 bg-neutral-100 shadow-lg"
                      data-cy={`${convertToLowerCaseWithDashes(
                        title,
                      )}-need-attention-tooltip`}
                    >
                      <Tooltip className="h-auto w-full max-w-[240px] flex-none">
                        {type !== 'guardrails' ? (
                          <RiskPopoverData
                            title={getNeedAttentionTooltip(type)}
                            detailedCounts={!hideSeverity}
                            critical={data?.Critical.toString()}
                            high={data?.High.toString()}
                            medium={data?.Medium.toString()}
                            low={data?.Low.toString()}
                            ignored={(
                              (totalCount || 0) -
                              (data?.UnignoredLARsCount || 0)
                            ).toString()}
                            total={data?.UnignoredLARsCount.toString()}
                          />
                        ) : undefined}
                      </Tooltip>
                    </div>
                  </SubframeCore.HoverCard.Content>
                </SubframeCore.HoverCard.Portal>
              </SubframeCore.HoverCard.Root>
            )}
          </>
        }
      />
    </RouterLink>
  );
}

export default function ClusterARSigsOverview({
  DefectsCount,
  MisconfigurationsCount,
  SystemRequirementsCount,
  DeprecationsCount,
  UnsupportedVersionsCount,
  VersionIncompatibilitiesCount,
  BestPracticesCount,
  UnignoredDefects,
  UnignoredMisconfigurations,
  UnignoredSystemRequirements,
  UnignoredDeprecations,
  UnignoredUnsupportedVersions,
  UnignoredVersionIncompatibilities,
  UnignoredBestPractices,
  loading,
}: ARSigOveriewProps) {
  const { client: StatsigClient } = useStatsigClient();
  const showSystemRequirements = StatsigClient.checkGate(
    'show_system_requirements',
  );

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <div className="flex w-full flex-col items-start gap-2 px-1 py-1">
        <span
          className="text-subheader font-subheader text-default-font"
          id="availability-risks-overview-title"
        >
          Operational Risks
        </span>
      </div>

      <div
        className="flex w-full flex-wrap items-center gap-6"
        id="availability-risks-overview-details"
      >
        <ARSigsOverviewCard
          title="Unsupported Versions"
          type="unsupported-versions"
          icon="mdi:layers-remove"
          data={
            UnignoredUnsupportedVersions
              ? ARSigsSeverityCounts(UnignoredUnsupportedVersions)
              : undefined
          }
          hideSeverity={true}
          totalCount={UnsupportedVersionsCount}
          loading={loading}
        />
        <ARSigsOverviewCard
          title="Defects"
          type="defects"
          icon="fluent-mdl2:defect-solid"
          data={
            UnignoredDefects
              ? ARSigsSeverityCounts(UnignoredDefects)
              : undefined
          }
          totalCount={DefectsCount}
          loading={loading}
        />
        <ARSigsOverviewCard
          title="Deprecations"
          type="deprecations"
          icon="carbon:in-progress-error"
          data={
            UnignoredDeprecations
              ? ARSigsSeverityCounts(UnignoredDeprecations)
              : undefined
          }
          hideSeverity={true}
          totalCount={DeprecationsCount}
          loading={loading}
        />

        <ARSigsOverviewCard
          title="Version Incompatibilities"
          type="version-incompatibilities"
          icon="teenyicons:layers-difference-solid"
          data={
            UnignoredVersionIncompatibilities
              ? ARSigsSeverityCounts(UnignoredVersionIncompatibilities)
              : undefined
          }
          hideSeverity={true}
          totalCount={VersionIncompatibilitiesCount}
          loading={loading}
        />
        <ARSigsOverviewCard
          title="Misconfigurations"
          type="misconfigurations"
          icon="fluent:error-circle-settings-20-regular"
          data={
            UnignoredMisconfigurations
              ? ARSigsSeverityCounts(UnignoredMisconfigurations)
              : undefined
          }
          totalCount={MisconfigurationsCount}
          loading={loading}
        />
        {showSystemRequirements && (
          <ARSigsOverviewCard
            title="System Requirements"
            type="system-requirements"
            icon="pajamas:requirements"
            data={
              UnignoredSystemRequirements
                ? ARSigsSeverityCounts(UnignoredSystemRequirements)
                : undefined
            }
            hideSeverity={true}
            totalCount={SystemRequirementsCount}
            loading={loading}
          />
        )}
      </div>
      <div className="flex w-full flex-col items-start gap-2 px-1 py-1">
        <span
          className="text-subheader font-subheader text-default-font"
          id="guardrails-overview-title"
        >
          Guardrails
        </span>
      </div>
      <div
        className="flex w-full flex-col items-start gap-2 px-1 py-1"
        id="guardrails-overview-details"
      >
        <ARSigsOverviewCard
          title="Guardrails"
          type="guardrails"
          icon="mingcute:certificate-fill"
          data={
            UnignoredBestPractices
              ? ARSigsSeverityCounts(UnignoredBestPractices)
              : undefined
          }
          hideSeverity={true}
          totalCount={BestPracticesCount}
          loading={loading}
        />
      </div>
    </div>
  );
}
