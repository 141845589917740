export const featureComparison = [
  {
    feature: 'Dimensions',
    tiers: [
      {
        feature: 'Clusters',
        business: 'Unlimited',
        enterprise: 'Unlimited',
      },
      {
        feature: 'Users',
        business: 'Unlimited',
        enterprise: 'Unlimited',
      },
      {
        feature: 'Nodes',
        business: 'Up to 100 included \n Additional nodes purchased separately',
        enterprise: 'Custom',
      },
      {
        feature: 'Cloud Providers',
        business: 'AWS, Azure, GCP',
        enterprise: 'AWS, Azure, GCP',
      },
      {
        feature: 'Kubernetes Distributions',
        business: 'EKS, GKE, AKS, Self-Hosted',
        enterprise: 'EKS, GKE, AKS, Self-Hosted',
      },
      {
        feature: 'Access Control',
        business: 'Passwordless Access (Gmail, Microsoft, GitHub) \n Email',
        enterprise:
          'Passwordless Access (Gmail, Microsoft, GitHub) \n Email \n SSO',
      },
      {
        feature: 'Organizations',
        business: '1',
        enterprise: 'Custom',
      },
      {
        feature: 'API Access',
        business: '-',
        enterprise: '✔️',
      },
      {
        feature: 'RBAC',
        business: '-',
        enterprise: '✔️',
      },
    ],
  },
  {
    feature: 'Risk Ledger',
    tiers: [
      {
        feature: 'Operational Risk Signatures',
        business: 'All included',
        enterprise: 'All included',
      },
      {
        feature: 'Mark & Ignore Risk',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'Create Jira Tickets',
        business: '✔️',
        enterprise: '✔️',
      },
    ],
  },
  {
    feature: 'Artifact Register',
    tiers: [
      {
        feature: 'Clusters',
        business: 'EKS, GKE, AKS, Self-Hosted',
        enterprise: 'EKS, GKE, AKS, Self-Hosted',
      },
      {
        feature: 'Add-ons',
        business: 'All included',
        enterprise: 'All included',
      },
      {
        feature: 'EOL Detection',
        business: '✔️',
        enterprise: '✔️',
      },
    ],
  },
  {
    feature: 'Upgrade Copilot',
    tiers: [
      {
        feature: 'Clusters',
        business: 'EKS, GKE, AKS, Self-Hosted',
        enterprise: 'EKS, GKE, AKS, Self-Hosted',
      },
      {
        feature: 'Add-ons',
        business: 'All included',
        enterprise: 'All included',
      },
      {
        feature: 'Preverification',
        business: 'With Upgrade Template purchase',
        enterprise: '✔️',
      },
    ],
  },
  {
    feature: 'Integrations',
    tiers: [
      {
        feature: 'Slack',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'Jira',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'GitHub',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'Webhooks',
        business: 'Purchased separately',
        enterprise: '✔️',
      },
      {
        feature: 'Custom Integrations (Third-party & In-house)',
        business: 'Purchased separately',
        enterprise: '✔️',
      },
    ],
  },
];

export const supportComparison = [
  {
    feature: 'Business Hours',
    basic: '0500-1700 Mon-Fri (GMT-8) \n 24x7 for P0',
    premium: '24x7',
  },
  {
    feature: 'Response times',
    basic:
      'P0: 1 hour \n P1: 4 business hours \n P2: 1 business day \n P3: 2 business days',
    premium: 'P0: 30 minutes \n P1: 1 hour \n P2: 4 hours \n P3: 1 day',
  },
  {
    feature: 'Channels',
    basic: 'Community Slack, Docs, Knowledge Base',
    premium: 'Private Slack Channel, Phone, Video, Email, Docs, Knowledge Base',
  },
];

export const pricingComparison = [
  {
    tier: 'Business',
    points: [
      'Clusters: Unlimited',
      'Users: Unlimited',
      'Risk Ledger: All Operational Risk Signatures included',
      'Artifact Register - Clusters: EKS, GKE, AKS, Self-Hosted',
      'Artifact Register - Add-ons: All Add-ons included',
      'Upgrade Copilot: Upgrade Templates purchased separately',
      'Nodes: Up to 100 included. Additional nodes purchased separately.',
    ],
  },
  {
    tier: 'Enterprise',
    points: [
      'SSO Support',
      'Support for multiple organizations',
      'Custom Integrations',
      'Optional Dedicated Hosted VPC support',
      'Custom contracts, Multi year contracts',
      'Premium support response SLA',
      'Contractual uptime guarantee',
      'Volume discounts available',
    ],
  },
];

// PRICING_OLD_VERSION
export const featureComparisonOld = [
  {
    feature: 'Dimensions',
    tiers: [
      {
        feature: 'Clusters',
        starter: 'Unlimited',
        business: 'Unlimited',
        enterprise: 'Unlimited',
      },
      {
        feature: 'Users',
        starter: 'Unlimited',
        business: 'Unlimited',
        enterprise: 'Unlimited',
      },
      {
        feature: 'Nodes',
        starter: '1-100 across clusters',
        business: '101-200 across clusters',
        enterprise: 'Custom',
      },
      {
        feature: 'Upgrade Copilot',
        starter: '-',
        business: '5 Upgrade Plans per yr',
        enterprise: 'Custom',
      },
      {
        feature: 'Cloud Providers',
        starter: 'AWS, Azure, GCP',
        business: 'AWS, Azure, GCP',
        enterprise: 'AWS, Azure, GCP',
      },
      {
        feature: 'Kubernetes Distributions',
        starter: 'EKS, GKE, AKS, Self-Hosted',
        business: 'EKS, GKE, AKS, Self-Hosted',
        enterprise: 'EKS, GKE, AKS, Self-Hosted, On-Premises',
      },
      {
        feature: 'Access Control',
        starter: 'Passwordless Access (Gmail, Microsoft, GitHub) \n Email',
        business: 'Passwordless Access (Gmail, Microsoft, GitHub) \n Email',
        enterprise:
          'Passwordless Access (Gmail, Microsoft, GitHub) \n Email \n SSO',
      },
      {
        feature: 'Organizations',
        starter: '1',
        business: 'Up to 5',
        enterprise: 'Up to 20',
      },
      {
        feature: 'API Access',
        starter: '-',
        business: '-',
        enterprise: '✔️',
      },
      {
        feature: 'RBAC',
        starter: '-',
        business: '-',
        enterprise: '✔️',
      },
      {
        feature: 'Data Retention',
        starter: '6 months',
        business: '2 years',
        enterprise: 'Custom',
      },
    ],
  },
  {
    feature: 'Risk Ledger',
    tiers: [
      {
        feature: 'Operational Risk Signatures',
        starter: 'All included',
        business: 'All included',
        enterprise: 'All included',
      },
      {
        feature: 'Cluster Scans per day',
        starter: 'Up to 5 per day',
        business: 'Up to 10 per day',
        enterprise: 'Custom',
      },
      {
        feature: 'Ignore Risk',
        starter: '✔️',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'Ticket Tracking',
        starter: '-',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'SLA for releasing user-reported Risk Signatures',
        starter: '-',
        business: '7 days',
        enterprise: '3 days',
      },
    ],
  },
  {
    feature: 'Artifact Register',
    tiers: [
      {
        feature: 'Clusters',
        starter: 'EKS, GKE, AKS, Self-Managed',
        business: 'EKS, GKE, AKS, Self-Managed',
        enterprise: 'EKS, GKE, AKS, Self-Managed',
      },
      {
        feature: 'Add-ons',
        starter: 'All (100+) included',
        business: 'All (100+) included',
        enterprise: 'All (100+) included',
      },
      {
        feature: 'EOL Detection',
        starter: '✔️',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'Container Repositories',
        starter: 'All (700+) covered',
        business: 'All (700+) covered',
        enterprise: 'All (700+) covered',
      },
      {
        feature: 'On-Demand Fleet Scans',
        starter: '-',
        business: '-',
        enterprise: '✔️',
      },
    ],
  },
  {
    feature: 'Upgrade Copilot',
    tiers: [
      {
        feature: 'Clusters',
        starter: '-',
        business: 'EKS, GKE, AKS, Self-Managed',
        enterprise: 'EKS, GKE, AKS, Self-Managed',
      },
      {
        feature: 'Add-ons',
        starter: '-',
        business: 'All (100+) included',
        enterprise: 'All (100+) included',
      },
      {
        feature: 'Preverification',
        starter: '-',
        business: 'Can be purchased independently',
        enterprise: '✔️',
      },
      {
        feature: 'Pull Request Generation',
        starter: '-',
        business: '-',
        enterprise: '✔️',
      },
    ],
  },
  {
    feature: 'Integrations',
    tiers: [
      {
        feature: 'Slack',
        starter: '✔️',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'Jira',
        starter: '-',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'GitHub',
        starter: '-',
        business: '✔️',
        enterprise: '✔️',
      },
      {
        feature: 'Webhooks',
        starter: '-',
        business: '-',
        enterprise: '✔️',
      },
      {
        feature: 'Custom Integrations (Third-party & In-house)',
        starter: '-',
        business: '-',
        enterprise: '✔️',
      },
    ],
  },
  {
    feature: 'Support & Compliance',
    tiers: [
      {
        feature: 'Channels',
        starter: 'Community Slack, Docs, Knowledge Base, Support Ticket',
        business:
          'Community Slack, Docs, Knowledge Base, Support Ticket, Email, Private Slack',
        enterprise:
          'Community Slack, Docs, Knowledge Base, Support Ticket, Email, Private Slack, Phone, Video',
      },
      {
        feature: 'Business Hours',
        starter: '0500-1700 Mon-Fri (GMT-8)',
        business: '0500-1700 Mon-Fri (GMT-8) \n 24x7 for P0',
        enterprise: '24x7 \n Dedicated Support + Pro Services',
      },
      {
        feature: 'Onboarding & Success',
        starter: 'Self Service install, Community support',
        business: 'Self Service install, Standard support',
        enterprise: 'Guided install & Onboarding assistance',
      },
      {
        feature: 'SOC2 Report',
        starter: '-',
        business: '✔️',
        enterprise: '✔️',
      },
    ],
  },
];

export const pricingComparisonOld = [
  {
    tier: 'Starter',
    points: [
      'Clusters: Unlimited',
      'Users: Unlimited',
      'Risk Ledger: All Operational Risk Signatures included',
      'Artifact Register - Clusters: EKS, GKE, AKS, Self-Hosted',
      'Artifact Register - Add-ons: All (100+) Add-ons included',
      'Nodes: 1-100 across clusters',
    ],
  },
  {
    tier: 'Business',
    points: [
      'Clusters: Unlimited',
      'Users: Unlimited',
      'Risk Ledger: All Operational Risk Signatures included',
      'Artifact Register - Clusters: EKS, GKE, AKS, Self-Managed',
      'Artifact Register - Add-ons: All (100+) Add-ons included',
      'Upgrade Copilot: 5 Upgrade Plans per year',
      'Nodes: 101-200 across clusters',
    ],
  },
  {
    tier: 'Enterprise',
    points: [
      'Clusters: Unlimited',
      'Users: Unlimited',
      'Risk Ledger: All Operational Risk Signatures included',
      'Artifact Register - Clusters: EKS, GKE, AKS, Self-Hosted, On-Premises',
      'Artifact Register - Add-ons: All (100+) Add-ons included',
      'Upgrade Copilot: Unlimited',
      'Nodes: Unlimited',
      'Dedicated Hosted VPC support',
      'Enterprise Access Control',
      'Custom Integrations',
    ],
  },
];
