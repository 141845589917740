import React, { useRef, useState } from 'react';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { AccordionWithUnderline, CodeBlock } from 'subframe/index';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import { TabsWithContent } from 'components/design-system/Tabs';
import { Diff } from 'subframe/components/Diff';
import TokenSelector from 'components/TokenSelector';
import { getUnixTime } from 'date-fns';
import { ListIngestionTokensResponse } from 'api/models';
import {
  CHKK_OPERATOR_VERSION,
  CHKK_TERRAFORM_K8S_CONNECTOR_VERSION,
} from 'constants/chkk-version';

function TroubleShootUpgradeK8sConnectorFAQ({
  tokens,
}: {
  tokens: ListIngestionTokensResponse | undefined;
}) {
  const [installMethod, setInstallMethod] = useState('');
  const [installMethodHelmTabs, setInstallMethodHelmTabs] = useState('');
  const [installMethodK8sYAMLTabs, setInstallMethodK8sYAMLTabs] = useState('');
  const [installMethodK8sTfTabs, setInstallMethodK8sTfTabs] = useState('');
  const [selectedToken, setSelectedToken] = useState<string | null>();
  const activeTokens = tokens?.data.filter((token) =>
    token.revoked
      ? token.revoked == 0 || token.revoked > getUnixTime(new Date())
      : true,
  );
  return (
    <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 border border-solid border-neutral-border px-4 py-4">
      <div className="flex w-full flex-col items-start gap-4">
        <span className="w-full text-body font-body text-default-font">
          This guide provides step-by-step instructions to upgrade the K8s
          Connector to the supported version.
        </span>
        <TabsWithContent
          initialTabId={installMethod || undefined}
          onTabChange={(newTabId) => {
            setInstallMethod(newTabId);
          }}
          tabs={[
            {
              id: 'default',
              title: 'Helm',
              content: (
                <div className="flex w-full flex-col items-start gap-4 px-1 py-1">
                  <span className="w-full text-body-bold font-body-bold text-default-font">
                    Step 1: Get the current version of Helm chart installed in
                    your K8s cluster
                  </span>
                  <CopyToClipboard
                    className="h-auto w-full flex-none"
                    text="helm list -n chkk-system -o json"
                  />
                  <span className="w-full text-body font-body text-default-font">
                    Sample Output:
                  </span>
                  <AccordionWithUnderline headerText="Sample Output:">
                    <CodeBlock className="h-auto w-full flex-none">
                      {`[{"name":"chkk-operator","namespace":"chkk-system","revision":"1","updated":"2024-10-31 16:41:10.720135 UTC","status":"deployed","chart":"chkk-operator-0.0.9","app_version":"0.0.9"}]`}
                    </CodeBlock>
                  </AccordionWithUnderline>
                  <span className="w-full text-body-bold font-body-bold text-default-font">
                    Step 2: Update Helm repository
                  </span>
                  <span className="w-full text-body font-body text-default-font">
                    Update Helm repository to fetch latest chart
                  </span>
                  <CopyToClipboard
                    className="h-auto w-full flex-none"
                    text="helm repo update chkk"
                  />
                  <span className="w-full text-body-bold font-body-bold text-default-font">
                    Step 3: Upgrade K8s connector
                  </span>
                  <TabsWithContent
                    initialTabId={installMethodHelmTabs || undefined}
                    onTabChange={(newTabId) => {
                      setInstallMethodHelmTabs(newTabId);
                    }}
                    tabs={[
                      {
                        id: 'helm-create-new-secret-service-account',
                        title: 'Create new Secret and Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              Run the following command to upgrade the K8s
                              Connector to the latest version.
                            </span>
                            <TokenSelector
                              label=""
                              placeholder="Select a Token"
                              helpText=""
                              value={selectedToken!}
                              tokens={activeTokens!}
                              onValueChange={(value: string) => {
                                setSelectedToken(value);
                              }}
                            />
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text={`helm upgrade chkk-operator chkk/chkk-operator --namespace chkk-system --set secret.chkkAccessToken=${
                                selectedToken ? selectedToken : '<ACCESS-TOKEN>'
                              }`}
                            />
                          </>
                        ),
                      },
                      {
                        id: 'helm-existing-secret',
                        title: 'Use existing Secret with new Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              You can use a separately configured secret object
                              containing your Chkk access token.
                            </span>
                            <span className="text-body font-body text-default-font">
                              Verify Chkk access token secret exists:
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text="kubectl get secret chkk-operator-token -n chkk-system"
                            />
                            <AccordionWithUnderline headerText="Sample Output:">
                              <CodeBlock className="h-auto w-full flex-none">
                                {[
                                  'NAME                   READY     DATA      AGE',
                                  'chkk-operator-token    Opaque      1       3m3s',
                                ].join('\n')}
                              </CodeBlock>
                            </AccordionWithUnderline>
                            <span className="text-body font-body text-default-font">
                              Run the following command to upgrade K8s
                              Connector:
                            </span>
                            <CopyToClipboard
                              data-cy="helm-verify-chkk-agent-output"
                              className="h-auto w-full flex-none"
                              text={`helm upgrade chkk-operator chkk/chkk-operator --namespace chkk-system --set secret.ref.secretName=chkk-operator-token --set secret.ref.keyName=CHKK_ACCESS_TOKEN --set secret.create=false`}
                            />
                          </>
                        ),
                      },
                      {
                        id: 'helm-existing-secret-service-account',
                        title: 'Use existing Secret and Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              You can use an existing service account and Chkk
                              access token secret to upgrade K8s Connector.
                            </span>
                            <span className="text-body font-body text-default-font">
                              Verify the service account and chkk access token
                              secret exists
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text="kubectl get secret chkk-operator-token -n chkk-system"
                            />
                            <AccordionWithUnderline headerText="Sample Output:">
                              <CodeBlock className="h-auto w-full flex-none">
                                {[
                                  'NAME                   READY     DATA      AGE',
                                  'chkk-operator-token    Opaque      1       3m3s',
                                ].join('\n')}
                              </CodeBlock>
                            </AccordionWithUnderline>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text="kubectl get serviceaccount chkk-operator -n chkk-system"
                            />
                            <AccordionWithUnderline headerText="Sample Output:">
                              <CodeBlock className="h-auto w-full flex-none">
                                {[
                                  'apiVersion: v1',
                                  'kind: ServiceAccount',
                                  'metadata:',
                                  '  annotations:',
                                  '    kubectl.kubernetes.io/last-applied-configuration: | ',
                                  '      {"apiVersion":"v1","kind":"ServiceAccount","metadata":{"annotations":{},',
                                  '"name":"chkk-operator","namespace":"chkk-system"}}',
                                  '  creationTimestamp: "2024-11-05T11:10:20Z"',
                                  '  name: chkk-operator',
                                  '  namespace: chkk-system',
                                  '  resourceVersion: "44216"',
                                  '  uid: 64b8e74f-8ddb-4c2d-8282-609bce1ec0d4',
                                ].join('\n')}
                              </CodeBlock>
                            </AccordionWithUnderline>
                            <span className="text-body font-body text-default-font">
                              Run the following command to upgrade K8s
                              Connector:
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text={`helm upgrade chkk-operator chkk/chkk-operator --namespace chkk-system
 --set secret.ref.secretName=chkk-operator-token --set secret.ref.keyName=CHKK_ACCESS_TOKEN --set secret.create=false --set serviceAccount.create=false --set serviceAccount.name=chkk-operator`}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                  <span className="w-full text-body-bold font-body-bold text-default-font">
                    Step 4: Verify the K8s Connector Upgrade
                  </span>
                  <CopyToClipboard
                    className="h-auto w-full flex-none"
                    text={
                      'kubectl get deployment.apps/chkk-operator --namespace chkk-system --output json | jq ".spec.template.spec.containers[].image"'
                    }
                  />
                  <AccordionWithUnderline headerText="Sample Output:">
                    <CodeBlock
                      data-cy="helm-verify-chkk-agent-output"
                      className="h-auto w-full flex-none"
                    >
                      {`"public.ecr.aws/chkk/operator:${CHKK_OPERATOR_VERSION}"`}
                    </CodeBlock>
                  </AccordionWithUnderline>
                </div>
              ),
            },
            {
              id: 'yaml-based-install',
              title: 'K8s YAML',
              content: (
                <>
                  <span className="w-full text-body-bold font-body-bold text-default-font">
                    Upgrade K8s Connector
                  </span>
                  <TabsWithContent
                    initialTabId={installMethodK8sYAMLTabs || undefined}
                    onTabChange={(newTabId) => {
                      setInstallMethodK8sYAMLTabs(newTabId);
                    }}
                    tabs={[
                      {
                        id: 'k8s-yaml-create-new-secret-service-account',
                        title: 'Create new Secret and Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              Run the following command to upgrade the K8s
                              Connector to the latest version.
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text={
                                'kubectl apply -f https://helm.chkk.io/chkk-operator/manifest.yaml'
                              }
                            />
                          </>
                        ),
                      },
                      {
                        id: 'k8s-yaml-existing-secret-new-service-account',
                        title: 'Use existing Secret with new Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              You can use a separately configured secret object
                              containing your Chkk access token.
                            </span>
                            <span className="text-body font-body text-default-font">
                              Verify the service account and chkk access token
                              secret exists:
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text="kubectl get secret chkk-operator-token -n chkk-system"
                            />
                            <AccordionWithUnderline headerText="Sample Output:">
                              <CodeBlock
                                data-cy="helm-verify-chkk-agent-output"
                                className="h-auto w-full flex-none"
                              >
                                {[
                                  'NAME                   READY     DATA      AGE',
                                  'chkk-operator-token    Opaque      1       3m3s',
                                ].join('\n')}
                              </CodeBlock>
                            </AccordionWithUnderline>
                            <span className="text-body font-body text-default-font">
                              Download the Chkk K8s Operator YAML manifest:
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text={
                                'wget https://helm.chkk.io/chkk-operator/manifest.yaml'
                              }
                            />
                            <span className="text-body font-body text-default-font">
                              Apply the following diff to manifest.yaml,
                              referencing your existing secret:
                            </span>
                            <Diff
                              className="h-auto w-full flex-none"
                              clipboardText={[
                                'name: <YOUR-SECRET>',
                                'key: <YOUR-SECRET-KEY>',
                              ].join('\n')}
                            >
                              {[
                                '<    name: chkk-operator',
                                '<    key: CHKK_ACCESS_TOKEN',
                                '---',
                                '>    name: <YOUR-SECRET>',
                                '>    key: <YOUR-SECRET-KEY>',
                              ].map((line, i) => {
                                if (line.startsWith('<')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="deleted"
                                      language="shell"
                                    />
                                  );
                                } else if (line.startsWith('>')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="added"
                                      language="shell"
                                    />
                                  );
                                } else {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line}
                                      language="shell"
                                    />
                                  );
                                }
                              })}
                            </Diff>
                            <span className="text-body font-body text-default-font">
                              Deploy the Chkk Operator using updated
                              manifest.yaml:
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text={'kubectl apply -f manifest.yaml'}
                            />
                          </>
                        ),
                      },
                      {
                        id: 'k8s-yaml-existing-secret-service-account',
                        title:
                          'Use existing Secret and existing Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              You can use an existing service account and Chkk
                              access token secret to upgrade K8s Connector.
                            </span>
                            <span className="text-body font-body text-default-font">
                              Verify service account and chkk access token
                              secret
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text="kubectl get secret chkk-operator-token -n chkk-system"
                            />
                            <AccordionWithUnderline headerText="Sample Output:">
                              <CodeBlock className="h-auto w-full flex-none">
                                {[
                                  'NAME                   READY     DATA      AGE',
                                  'chkk-operator-token    Opaque      1       3m3s',
                                ].join('\n')}
                              </CodeBlock>
                            </AccordionWithUnderline>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text="kubectl get serviceaccount chkk-operator -n chkk-system"
                            />
                            <AccordionWithUnderline headerText="Sample Output:">
                              <CodeBlock className="h-auto w-full flex-none">
                                {[
                                  'apiVersion: v1',
                                  'kind: ServiceAccount',
                                  'metadata:',
                                  '  annotations:',
                                  '    kubectl.kubernetes.io/last-applied-configuration: | ',
                                  '      {"apiVersion":"v1","kind":"ServiceAccount","metadata":{"annotations":{},',
                                  '"name":"chkk-operator","namespace":"chkk-system"}}',
                                  '  creationTimestamp: "2024-11-05T11:10:20Z"',
                                  '  name: chkk-operator',
                                  '  namespace: chkk-system',
                                  '  resourceVersion: "44216"',
                                  '  uid: 64b8e74f-8ddb-4c2d-8282-609bce1ec0d4',
                                ].join('\n')}
                              </CodeBlock>
                            </AccordionWithUnderline>
                            <span className="text-body font-body text-default-font">
                              Download the Chkk K8s Operator YAML manifest:
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text="wget https://helm.chkk.io/chkk-operator/manifest.yaml"
                            />
                            <span className="text-body font-body text-default-font">
                              Apply the following diff to manifest.yaml,
                              referencing your existing secret:
                            </span>
                            <Diff
                              className="h-auto w-full flex-none"
                              clipboardText={[
                                ' containers:',
                                '   - args:',
                                '     - --leader-elect',
                                '     env:',
                                '       - name: CHKK_ACCESS_TOKEN',
                                `         image: "public.ecr.aws/chkk/operator:${CHKK_OPERATOR_VERSION}"`,
                                '         valueFrom:',
                                '           secretKeyRef:',
                                '             name: <YOUR_SECRET>',
                                '             key: <YOUR_KEY>',
                                ' serviceAccountName: <YOUR_SERVICE_ACCOUNT>',
                              ].join('\n')}
                            >
                              {[
                                ' containers:',
                                '   - args:',
                                '     - --leader-elect',
                                '     env:',
                                '       - name: CHKK_ACCESS_TOKEN',
                                `         image: "public.ecr.aws/chkk/operator:${CHKK_OPERATOR_VERSION}"`,
                                '         valueFrom:',
                                '           secretKeyRef:',
                                '-            name: chkk-operator',
                                '+            name: <YOUR_SECRET>',
                                '-            key: CHKK_ACCESS_TOKEN',
                                '+            key: <YOUR_KEY>',
                                '-  serviceAccountName: chkk-operator-sa',
                                '+  serviceAccountName: <YOUR_SERVICE_ACCOUNT>',
                              ].map((line, i) => {
                                if (line.startsWith('-')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="deleted"
                                      language="shell"
                                    />
                                  );
                                } else if (line.startsWith('+')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="added"
                                      language="shell"
                                    />
                                  );
                                } else {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line}
                                      language="shell"
                                    />
                                  );
                                }
                              })}
                            </Diff>
                            <span className="text-body font-body text-default-font">
                              Deploy the Chkk Operator using updated
                              manifest.yaml:
                            </span>
                            <CopyToClipboard
                              className="h-auto w-full flex-none"
                              text="kubectl apply -f manifest.yaml"
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                  <span className="w-full text-body-bold font-body-bold text-default-font">
                    Verify the K8s Connector Upgrade
                  </span>
                  <CopyToClipboard
                    className="h-auto w-full flex-none"
                    text={
                      'kubectl get deployment.apps/chkk-operator --namespace chkk-system --output json | jq ".spec.template.spec.containers[].image"'
                    }
                  />
                  <AccordionWithUnderline headerText="Sample Output:">
                    <CodeBlock
                      data-cy="yaml-verify-chkk-agent-output"
                      className="h-auto w-full flex-none"
                    >
                      {`"public.ecr.aws/chkk/operator:${CHKK_OPERATOR_VERSION}"`}
                    </CodeBlock>
                  </AccordionWithUnderline>
                </>
              ),
            },
            {
              id: 'terraform-based-install',
              title: 'Terraform',
              content: (
                <>
                  <span className="text-body-bold font-body text-default-font">
                    Update Chart Version in K8s Connector Terraform module
                  </span>
                  <span className="text-body font-body text-default-font">
                    Update the chart version in Chkk K8s Connector Terraform
                    module:
                  </span>
                  <TabsWithContent
                    initialTabId={installMethodK8sTfTabs || undefined}
                    onTabChange={(newTabId) => {
                      setInstallMethodK8sTfTabs(newTabId);
                    }}
                    tabs={[
                      {
                        id: 'terraform-create-new-secret-service-account',
                        title: 'Create a new Secret and Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              Update the chart version in Chkk K8s Connector
                              Terraform module:
                            </span>
                            <TokenSelector
                              label=""
                              placeholder="Select a Token"
                              helpText=""
                              value={selectedToken!}
                              tokens={activeTokens!}
                              onValueChange={(value: string) => {
                                setSelectedToken(value);
                              }}
                            />
                            <Diff
                              className="h-auto w-full flex-none"
                              clipboardText={[
                                'module "chkk_k8s_connector" {',
                                `  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"`,
                                '  create_namespace = true',
                                '  namespace        = "chkk-system"',
                                `  chart_version    = "${CHKK_OPERATOR_VERSION}"`,
                                '  chkk_operator_config = {',
                                '    secret = {',
                                '      create          = true',
                                `      chkkAccessToken = ${
                                  selectedToken
                                    ? selectedToken
                                    : '<ACCESS_TOKEN>'
                                }`,
                                '    }',
                                '}',
                              ].join('\n')}
                            >
                              {[
                                'module "chkk_k8s_connector" {',
                                `  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"`,
                                '  create_namespace = true',
                                '  namespace        = "chkk-system"',
                                `+  chart_version    = "${CHKK_OPERATOR_VERSION}"`,
                                '  chkk_operator_config = {',
                                '    secret = {',
                                '      create          = true',
                                `      chkkAccessToken = ${
                                  selectedToken
                                    ? selectedToken
                                    : '<ACCESS_TOKEN>'
                                }`,
                                '    }',
                                '}',
                              ].map((line, i) => {
                                if (line.startsWith('-')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="deleted"
                                      language="shell"
                                    />
                                  );
                                } else if (line.startsWith('+')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="added"
                                      language="shell"
                                    />
                                  );
                                } else {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line}
                                      language="shell"
                                    />
                                  );
                                }
                              })}
                            </Diff>
                          </>
                        ),
                      },
                      {
                        id: 'terraform-use-existing-secret-new-service-account',
                        title: 'Use existing Secret with new Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              Update the chart version in Chkk K8s Connector
                              Terraform module:
                            </span>
                            <Diff
                              className="h-auto w-full flex-none"
                              clipboardText={[
                                'module "chkk_k8s_connector" {',
                                `  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"`,
                                '',
                                '  create_namespace = false',
                                '  namespace        = "chkk-system"',
                                `  chart_version    = "${CHKK_OPERATOR_VERSION}"`,
                                '',
                                '  chkk_operator_config = {',
                                '    secret = {',
                                '      create = false',
                                '      ref = {',
                                '        secretName = "chkk-operator-token",',
                                '        keyName    = "CHKK_ACCESS_TOKEN"',
                                '      }',
                                '    }',
                                '  }',
                                '',
                                '  chkk_agent_config = {',
                                '    secret = {',
                                '      secretName = "chkk-agent-token",',
                                '      keyName    = "CHKK_ACCESS_TOKEN"',
                                '    }',
                                '  }',
                                '}',
                              ].join('\n')}
                            >
                              {[
                                'module "chkk_k8s_connector" {',
                                `  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"`,
                                '',
                                '  create_namespace = false',
                                '  namespace        = "chkk-system"',
                                `+  chart_version    = "${CHKK_OPERATOR_VERSION}"`,
                                '',
                                '  chkk_operator_config = {',
                                '    secret = {',
                                '      create = false',
                                '      ref = {',
                                '        secretName = "chkk-operator-token",',
                                '        keyName    = "CHKK_ACCESS_TOKEN"',
                                '      }',
                                '    }',
                                '  }',
                                '',
                                '  chkk_agent_config = {',
                                '    secret = {',
                                '      secretName = "chkk-agent-token",',
                                '      keyName    = "CHKK_ACCESS_TOKEN"',
                                '    }',
                                '  }',
                                '}',
                              ].map((line, i) => {
                                if (line.startsWith('-')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="deleted"
                                      language="shell"
                                    />
                                  );
                                } else if (line.startsWith('+')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="added"
                                      language="shell"
                                    />
                                  );
                                } else {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line}
                                      language="shell"
                                    />
                                  );
                                }
                              })}
                            </Diff>
                          </>
                        ),
                      },
                      {
                        id: 'terraform-existing-secret-service-account',
                        title: 'Use existing Secret and Service Account',
                        content: (
                          <>
                            <span className="text-body font-body text-default-font">
                              Update the chart version in Chkk K8s Connector
                              Terraform module:
                            </span>
                            <Diff
                              className="h-auto w-full flex-none"
                              clipboardText={[
                                'module "chkk_k8s_connector" {',
                                `  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"`,
                                '',
                                '  create_namespace = false',
                                '  namespace        = "chkk-system"',
                                `  chart_version    = "${CHKK_OPERATOR_VERSION}"`,
                                '',
                                '  chkk_operator_config = {',
                                '    secret = {',
                                '      create = false',
                                '      ref = {',
                                '        secretName = "chkk-operator-token",',
                                '        keyName    = "CHKK_ACCESS_TOKEN"',
                                '      }',
                                '    }',
                                '    serviceAccount = {',
                                '      create = false',
                                '      name   = "chkk-operator"',
                                '    }',
                                '  }',
                                '',
                                '  chkk_agent_config = {',
                                '    secret = {',
                                '      secretName = "chkk-agent-token",',
                                '      keyName    = "CHKK_ACCESS_TOKEN"',
                                '    }',
                                '    serviceAccount = {',
                                '      create = false',
                                '      name   = "chkk-agent"',
                                '    }',
                                '  }',
                                '}',
                              ].join('\n')}
                            >
                              {[
                                'module "chkk_k8s_connector" {',
                                `  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"`,
                                '',
                                '  create_namespace = false',
                                '  namespace        = "chkk-system"',
                                `+  chart_version    = "${CHKK_OPERATOR_VERSION}"`,
                                '',
                                '  chkk_operator_config = {',
                                '    secret = {',
                                '      create = false',
                                '      ref = {',
                                '        secretName = "chkk-operator-token",',
                                '        keyName    = "CHKK_ACCESS_TOKEN"',
                                '      }',
                                '    }',
                                '    serviceAccount = {',
                                '      create = false',
                                '      name   = "chkk-operator"',
                                '    }',
                                '  }',
                                '',
                                '  chkk_agent_config = {',
                                '    secret = {',
                                '      secretName = "chkk-agent-token",',
                                '      keyName    = "CHKK_ACCESS_TOKEN"',
                                '    }',
                                '    serviceAccount = {',
                                '      create = false',
                                '      name   = "chkk-agent"',
                                '    }',
                                '  }',
                                '}',
                              ].map((line, i) => {
                                if (line.startsWith('-')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="deleted"
                                      language="shell"
                                    />
                                  );
                                } else if (line.startsWith('+')) {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line.substring(1, line.length)}
                                      variant="added"
                                      language="shell"
                                    />
                                  );
                                } else {
                                  return (
                                    <Diff.Line
                                      key={i}
                                      text={line}
                                      language="shell"
                                    />
                                  );
                                }
                              })}
                            </Diff>
                          </>
                        ),
                      },
                    ]}
                  />
                  <span className="text-body-bold font-body text-default-font">
                    Verify the K8s Connector Upgrade
                  </span>
                  <CopyToClipboard
                    className="h-auto w-full flex-none"
                    text={
                      'kubectl get deployment.apps/chkk-operator --namespace chkk-system --output json | jq ".spec.template.spec.containers[].image"'
                    }
                  />
                  <AccordionWithUnderline headerText="Sample Output:">
                    <CodeBlock
                      data-cy="tf-verify-chkk-agent-output"
                      className="h-auto w-full flex-none"
                    >
                      {`"public.ecr.aws/chkk/operator:${CHKK_OPERATOR_VERSION}"`}
                    </CodeBlock>
                  </AccordionWithUnderline>
                </>
              ),
            },
          ]}
        />
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-border" />
      </div>
    </div>
  );
}

export default TroubleShootUpgradeK8sConnectorFAQ;
