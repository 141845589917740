import React, { useEffect, useRef, useState } from 'react';
import * as SubframeCore from '@subframe/core';
import { Text } from '@subframe/core';

import useUserAccountState from 'hooks/useUserAccountState';
import { useListIngestionTokens } from 'api/frontend';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

import {
  Accordion,
  AccordionWithUnderline,
  Breadcrumbs,
  Button,
  CodeBlock,
  Tabs,
} from 'subframe/index';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import { TabsWithContent } from 'components/design-system/Tabs';
import TokenSelector from 'components/TokenSelector';
import { getUnixTime } from 'date-fns';
import { Diff } from 'subframe/components/Diff';
import TroubleShootUpgradeK8sConnectorFAQ from 'pages/TroubleshootUpgradeK8sConnectorFAQ';
import {
  CHKK_CLUSTER_AGENT_VERSION,
  CHKK_OPERATOR_VERSION,
  CHKK_TERRAFORM_K8S_CONNECTOR_VERSION,
} from 'constants/chkk-version';

export enum TroubleshootingProblems {
  TOKEN_NOT_AUTHORIZED = 'token-not-authorized',
  USE_EXISTING_SERVICE_ACCOUNT = 'use-existing-service-account-with-chkk-agent',
  USE_EXISTING_SECRET = 'use-existing-secret-with-chkk-agent',
  CANT_CREATE_JIRA_TICKETS = 'cant-create-jira-tickets',
  IGNORE_AVAILABILITY_RISK = 'ignore-availability-risk',
  CONFIGURE_CLUSTER_NAME_ENV = 'configure-cluster-name-and-environment',
  CONFIGURE_CLUSTER_NAME_ENV_TERRAFORM = 'configure-cluster-name-and-environment-terraform',
  RESOLVE_FAILED_TO_DETERMINE = 'resolve-failed-to-determine-if-v1-configmap-is-namespaced-forbidden',
  RESOLVE_GET_INGESTION = 'resolve-get-ingestion',
  UNINSTALL_CHKK_OPERATOR = 'uninstall-chkk-operator',
  ACTIVATE_CLUSTER_INSTRUCTIONS = 'activate-cluster-instructions',
  OVERRIDE_CHKK_IMAGES = 'override-chkk-images',
  UPGRADE_K8S_CONNECTOR = 'upgrade-k8s-connector',
}

function TroubleshootingK8SConnector() {
  const { account } = useUserAccountState();
  const [problem] = useState(
    new URLSearchParams(window.location.search).get('problem'),
  );
  const [installMethod, setInstallMethod] = useState('');
  const [selectedToken, setSelectedToken] = useState<string | null>();
  const { data: tokens } = useListIngestionTokens('default', {
    request: {
      headers: {
        Authorization: `Bearer ${account?.token}`,
      },
    },
    swr: {
      revalidateOnFocus: false,
    },
  });
  const activeTokens = tokens?.data.filter((token) =>
    token.revoked
      ? token.revoked == 0 || token.revoked > getUnixTime(new Date())
      : true,
  );
  const clustersPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  const addTokenPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/settings/tokens/new',
  );
  const [openAccordion, setOpenAccordion] = useState<string>();
  const SELECT_ACCESS_TOKEN = 'Select an access token from the above dropdown.';

  const tokenNotAuthorizedRef = useRef(null);
  const existingServiceAccountRef = useRef(null);
  const existingSecretRef = useRef(null);
  const cantCreateJiraTicketsRef = useRef(null);
  const ignoreAvailabilityRiskRef = useRef(null);
  const configureClusterNameEnvRef = useRef(null);
  const configureClusterNameTerraformRef = useRef(null);
  const resolveFailedToDetermineRef = useRef(null);
  const resolveGetIngestionRef = useRef(null);
  const uninstallChkkOperatorRef = useRef(null);
  const activateDeactivateClusterRef = useRef(null);
  const overrideChkkImagesRef = useRef(null);
  const upgradeK8sConnectorRef = useRef(null);

  const handleOpen = (id: string, ref?: React.RefObject<HTMLElement>) => {
    setOpenAccordion(id);
    const int = setTimeout(() => {
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      clearTimeout(int);
    }, 400);
  };

  // This will be called only once when page is visited using direct URL
  useEffect(() => {
    switch (problem) {
      case TroubleshootingProblems.TOKEN_NOT_AUTHORIZED:
        handleOpen(
          TroubleshootingProblems.TOKEN_NOT_AUTHORIZED,
          tokenNotAuthorizedRef,
        );
        break;
      case TroubleshootingProblems.USE_EXISTING_SERVICE_ACCOUNT:
        handleOpen(
          TroubleshootingProblems.USE_EXISTING_SERVICE_ACCOUNT,
          existingServiceAccountRef,
        );
        break;
      case TroubleshootingProblems.USE_EXISTING_SECRET:
        handleOpen(
          TroubleshootingProblems.USE_EXISTING_SECRET,
          existingSecretRef,
        );
        break;
      case TroubleshootingProblems.CANT_CREATE_JIRA_TICKETS:
        handleOpen(
          TroubleshootingProblems.CANT_CREATE_JIRA_TICKETS,
          cantCreateJiraTicketsRef,
        );
        break;
      case TroubleshootingProblems.IGNORE_AVAILABILITY_RISK:
        handleOpen(
          TroubleshootingProblems.IGNORE_AVAILABILITY_RISK,
          ignoreAvailabilityRiskRef,
        );
        break;
      case TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV:
        handleOpen(
          TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV,
          configureClusterNameEnvRef,
        );
        break;
      case TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV_TERRAFORM:
        handleOpen(
          TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV_TERRAFORM,
          configureClusterNameTerraformRef,
        );
        break;
      case TroubleshootingProblems.RESOLVE_FAILED_TO_DETERMINE:
        handleOpen(
          TroubleshootingProblems.RESOLVE_FAILED_TO_DETERMINE,
          resolveFailedToDetermineRef,
        );
        break;
      case TroubleshootingProblems.RESOLVE_GET_INGESTION:
        handleOpen(
          TroubleshootingProblems.RESOLVE_GET_INGESTION,
          resolveGetIngestionRef,
        );
        break;
      case TroubleshootingProblems.UNINSTALL_CHKK_OPERATOR:
        handleOpen(
          TroubleshootingProblems.UNINSTALL_CHKK_OPERATOR,
          uninstallChkkOperatorRef,
        );
        break;
      case TroubleshootingProblems.ACTIVATE_CLUSTER_INSTRUCTIONS:
        handleOpen(
          TroubleshootingProblems.ACTIVATE_CLUSTER_INSTRUCTIONS,
          activateDeactivateClusterRef,
        );
        break;
      case TroubleshootingProblems.OVERRIDE_CHKK_IMAGES:
        handleOpen(
          TroubleshootingProblems.OVERRIDE_CHKK_IMAGES,
          overrideChkkImagesRef,
        );
        break;
      case TroubleshootingProblems.UPGRADE_K8S_CONNECTOR:
        handleOpen(
          TroubleshootingProblems.UPGRADE_K8S_CONNECTOR,
          upgradeK8sConnectorRef,
        );
        break;
      default:
        handleOpen(
          TroubleshootingProblems.TOKEN_NOT_AUTHORIZED,
          tokenNotAuthorizedRef,
        );
        break;
    }
  }, []);

  return (
    <Page title="Troubleshooting">
      <div className="flex h-full w-[calc(100%-64px)] flex-col items-start gap-6 pt-8 pb-0 px-3 bg-default-background ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Explore</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Troubleshooting</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col items-start gap-2">
            <span className="text-header text-default-font">
              Troubleshooting
            </span>
            <span className="text-body text-subtext-color">
              Troubleshoot and optimize Chkk&#39;s integration with your cluster
            </span>
          </div>
          <div>
            <RouterLink to={clustersPath}>
              <Button variant="brand-secondary" size="medium">
                Go to Clusters
              </Button>
            </RouterLink>
          </div>
        </div>
        {/*Problem - 1*/}
        <Accordion
          trigger={
            <>
              <div
                className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
                id={TroubleshootingProblems.TOKEN_NOT_AUTHORIZED}
                ref={tokenNotAuthorizedRef}
              >
                <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                  How do I resolve the “Token not authorized” errors coming from
                  ChkkAgent pods?
                </span>
                <Accordion.Chevron />
              </div>
              <CodeBlock className="h-auto w-full flex-none" language="shell">
                Token not authorized. Make sure you are using the correct
                ingestion token. Visit
                https://app.chkk.io/troubleshoot-k8s-connector for more details.
              </CodeBlock>
            </>
          }
          open={openAccordion === TroubleshootingProblems.TOKEN_NOT_AUTHORIZED}
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.TOKEN_NOT_AUTHORIZED : '',
            );
          }}
        >
          <div className="flex w-full flex-col items-start border-r border-l border-b  border-solid border-neutral-border p-4">
            <div className="flex w-full flex-col items-start gap-4 pt-2.5">
              <span className="w-full text-body font-body text-default-font">
                This error is caused by invalid/missing token. If you are using
                a mounted secret as described{' '}
                <a
                  href="https://github.com/chkk-io/k8s-connector"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                , you will need to update your secret to use a valid token:
              </span>
              <TokenSelector
                label=""
                placeholder="Select a Token"
                helpText=""
                value={selectedToken!}
                tokens={activeTokens!}
                onValueChange={(value: string) => {
                  setSelectedToken(value);
                }}
              />
              <div className="flex w-full flex-col items-start gap-2">
                {selectedToken ? (
                  <CopyToClipboard
                    language={selectedToken ? 'shell' : 'plaintext'}
                    className="h-auto w-full flex-none sensitive select-none cursor-pointer ph-no-capture"
                    text={selectedToken || SELECT_ACCESS_TOKEN}
                    singleLine={false}
                  />
                ) : (
                  <CopyToClipboard
                    className="h-auto w-full flex-none select-none cursor-pointer"
                    text={SELECT_ACCESS_TOKEN}
                    singleLine={false}
                  />
                )}
                <span className="w-full text-body font-body text-default-font">
                  If you are using a Helm-based installation, you can upgrade
                  the Helm chart with the correct token by following the
                  instructions below.
                </span>
                <span className="w-full text-body font-body text-default-font">
                  Run the following command to upgrade your Helm chart release:
                </span>
                {selectedToken ? (
                  <CopyToClipboard
                    language="shell"
                    className="h-auto w-full flex-none sensitive select-none cursor-pointer ph-no-capture"
                    text={`helm upgrade chkk-operator chkk/chkk-operator --namespace chkk-system --set secret.chkkAccessToken=${selectedToken}`}
                    singleLine={false}
                  />
                ) : (
                  <CopyToClipboard
                    language="shell"
                    className="h-auto w-full flex-none select-none cursor-pointer"
                    text={`helm upgrade chkk-operator chkk/chkk-operator --namespace chkk-system --set secret.chkkAccessToken=<ACCESS-TOKEN>`}
                    singleLine={false}
                  />
                )}
                <AccordionWithUnderline headerText="Sample Output">
                  <CodeBlock className="h-auto w-full flex-none">{`Release "chkk-operator" has been upgraded. Happy Helming!
NAME: chkk-operator
LAST DEPLOYED: Thu Aug 17 19:31:58 2023
NAMESPACE: chkk-system
STATUS: deployed
REVISION: 2
TEST SUITE: None`}</CodeBlock>
                </AccordionWithUnderline>
              </div>
              <div className="flex w-full flex-col items-start gap-2">
                <span className="w-full text-body font-body text-default-font">
                  Run the following command to confirm the status of
                  agent-manager pod:
                </span>
                <CopyToClipboard
                  language="shell"
                  text="kubectl get pods -n chkk-system"
                />
                <AccordionWithUnderline headerText="Sample Output">
                  <CodeBlock className="h-auto w-full flex-none">{`NAME                                            READY     STATUS    RESTARTS     AGE
chkk-operator-chkk-agent-3kjfqe00fqpe-atpoiks   1/1       Running        0       4d13h`}</CodeBlock>
                </AccordionWithUnderline>
              </div>
              <span className="w-full text-body font-body text-default-font">
                Note: It takes 5-10 seconds for helm upgrade to update
                deployments.
              </span>
              <span className="w-full text-body font-body text-default-font">
                If the problem still persists, feel free to reach out to us on{' '}
                <a
                  href="https://slack.chkk.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  Slack
                </a>{' '}
                or email us at{' '}
                <a href="mailto:support@chkk.io">support@chkk.io</a>.
              </span>
            </div>
          </div>
        </Accordion>
        {/*Problem - 2*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.USE_EXISTING_SERVICE_ACCOUNT}
              ref={existingServiceAccountRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How to use an existing Service Account with the Chkk K8s
                Connector?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={
            openAccordion ===
            TroubleshootingProblems.USE_EXISTING_SERVICE_ACCOUNT
          }
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen
                ? TroubleshootingProblems.USE_EXISTING_SERVICE_ACCOUNT
                : '',
            );
          }}
        >
          <div className="flex w-full flex-col items-start border-r border-l border-b  border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4">
            <div className="flex w-full flex-col items-start gap-4">
              <span className="w-full text-body font-body text-default-font">
                To use an existing Service Account with the Chkk k8s Connector,
                you will need to provide a reference to it as part of the
                installation of the operator:
              </span>
              <CopyToClipboard
                language="shell"
                className="h-auto w-full flex-none"
                text="helm install chkk-operator chkk/chkk-operator --namespace chkk-system --set serviceAccount.name=<YOUR-ACCOUNT-NAME> --set serviceAccount.create=false"
                singleLine={false}
              />
              <div className="flex w-full flex-col items-start gap-2">
                <span className="w-full text-body font-body text-default-font">
                  When creating the ChkkAgent custom resource, start with the
                  following example that shows how you can modify ChkkAgent
                  default configuration to mount your Service Account:
                </span>
                <CopyToClipboard
                  language="yaml"
                  className="h-auto w-full flex-none"
                  text={
                    'apiVersion: k8s.chkk.io/v1beta1\nkind: ChkkAgent\nmetadata:\n   name: chkk-agent\n   namespace: chkk-system\nspec:\n   agentOverride:\n      createRbac: false\n      serviceAccountName: <YOUR-ACCOUNT-NAME>'
                  }
                />
              </div>
            </div>
          </div>
        </Accordion>
        {/*Problem - 3*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.USE_EXISTING_SECRET}
              ref={existingSecretRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How to use an existing secret with the Chkk K8s Connector?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={openAccordion === TroubleshootingProblems.USE_EXISTING_SECRET}
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.USE_EXISTING_SECRET : '',
            );
          }}
        >
          <div className="flex w-full flex-col items-start border-r border-l border-b  border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4">
            <div className="flex w-full flex-col items-start gap-4">
              <span className="w-full text-body font-body text-default-font">
                To use an existing secret (e.g. provided by an external secrets
                system) with the Chkk K8s Connector, you need to provide a
                reference to it as part of the operator installation:
              </span>
              <CopyToClipboard
                language="shell"
                className="h-auto w-full flex-none"
                text="helm install chkk-operator chkk/chkk-operator --namespace chkk-system --set secret.ref.secretName=<YOUR-SECRET> --set secret.ref.keyName=<YOUR-SECRET-KEY> --set secret.create=false"
                singleLine={false}
              />
              <div className="flex w-full flex-col items-start gap-2">
                <span className="w-full text-body font-body text-default-font">
                  When creating the ChkkAgent custom resource, start with the
                  following example that shows how you can modify ChkkAgent
                  default configuration to use the existing secret:
                </span>
                <CopyToClipboard
                  language="yaml"
                  className="h-auto w-full flex-none"
                  text={
                    'apiVersion: k8s.chkk.io/v1beta1\nkind: ChkkAgent\nmetadata:\n  name: chkk-agent\n  namespace: chkk-system\nspec:\n  global:\n     credentials:\n       accessTokenSecret:\n         keyName: <YOUR-SECRET-KEY>\n         secretName: <YOUR-SECRET>'
                  }
                />
              </div>
              <span className="w-full text-body font-body text-default-font">
                If the problem still persists, feel free to reach out to us on{' '}
                <a
                  href="https://slack.chkk.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  Slack
                </a>{' '}
                or email us at{' '}
                <a href="mailto:support@chkk.io">support@chkk.io</a>.
              </span>
            </div>
          </div>
        </Accordion>
        {/*Problem - 4*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.CANT_CREATE_JIRA_TICKETS}
              ref={cantCreateJiraTicketsRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                Why can&#39;t I create Jira tickets for Operational Risks?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={
            openAccordion === TroubleshootingProblems.CANT_CREATE_JIRA_TICKETS
          }
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.CANT_CREATE_JIRA_TICKETS : '',
            );
          }}
        >
          <div className="flex w-full flex-col items-start border-r border-l border-b  border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4">
            <span className="w-full text-body font-body text-default-font">
              Make sure that the Jira project and issue type do not have any
              required custom fields. Chkk by default only supports providing
              the default fields to Jira. If you require the use of mandatory
              custom fields, contact us on{' '}
              <a href="https://slack.chkk.io" target="_blank" rel="noreferrer">
                Slack
              </a>{' '}
              or email us at{' '}
              <a href="mailto:support@chkk.io">support@chkk.io</a>.
            </span>
          </div>
        </Accordion>
        {/*Problem - 5*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.IGNORE_AVAILABILITY_RISK}
              ref={ignoreAvailabilityRiskRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How do I ignore a detected Operational Risk from my
                Infrastructure as Code (IaC)? Can I ignore specific resources
                that my team doesn’t own, instead of ignoring all affected
                resources?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={
            openAccordion === TroubleshootingProblems.IGNORE_AVAILABILITY_RISK
          }
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.IGNORE_AVAILABILITY_RISK : '',
            );
          }}
        >
          <div className="flex w-full flex-col items-start border-r border-l border-b  border-solid border-neutral-border pt-2 pr-2 pb-2 pl-2">
            <div className="flex w-full flex-col items-start gap-4">
              <span className="w-full text-body font-body text-default-font">
                In addition to offering functionality to Ignore a detected
                Operational Risk through the Chkk Dashboard, Chkk also provides
                an interface for directly managing an ignore list from your IaC
                code. You can maintain an ignore list for each Kubernetes
                resource by incorporating it into the resource&#39;s
                annotations, utilizing the annotation
                &quot;chkk.io/ignore&quot;.
              </span>
              <div className="flex w-full flex-col items-start gap-4">
                <span className="w-full text-body font-body text-default-font">
                  1. Ignore all Operational Risks detected for the resource. To
                  ignore all Operational Risks for a specific K8s resource, you
                  can specify a wildcard value (*) for the annotation. For
                  reference take a look at the example below:
                </span>
                <CopyToClipboard
                  language="yaml"
                  className="h-auto w-full flex-none"
                  text={
                    'apiVersion: apps/v1\nkind: Deployment\nmetadata:\n  annotations:\n    chkk.io/ignore: "*"\n    deployment.kubernetes.io/revision: "1"\n    meta.helm.sh/release-name: traefik-1\n    meta.helm.sh/release-namespace: traefik-ns'
                  }
                  singleLine={false}
                />
                <span className="w-full text-body font-body text-default-font">
                  In the above example, Chkk will Ignore all Operational Risks
                  detected for the traefik deployment resource.
                </span>
              </div>
              <div className="flex w-full flex-col items-start gap-4">
                <span className="w-full text-body font-body text-default-font">
                  2. Ignore specific Operational Risks detected for the
                  resource. To ignore one or more Operational Risks for a
                  specific K8s resource, specify a comma-separate list of ARSig
                  IDs in the annotation. For reference take a look at the
                  example below:
                </span>
                <CopyToClipboard
                  language="yaml"
                  className="h-auto w-full flex-none"
                  text={
                    'apiVersion: apps/v1\nkind: Deployment\nmetadata:\n  annotations:\n    chkk.io/ignore: CHKK-K8S-1002,CHKK-K8S-602\n    deployment.kubernetes.io/revision: "1"\n    meta.helm.sh/release-name: traefik-1\n    meta.helm.sh/release-namespace: traefik-ns'
                  }
                />
                <span className="w-full text-body font-body text-default-font">
                  In the above example, Chkk will Ignore 2 Operational Risks
                  (CHKK-K8S-1002,CHKK-K8S-602), if detected, for the traefik
                  deployment resource.
                </span>
              </div>
            </div>
          </div>
        </Accordion>
        {/*Problem - 6*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV}
              ref={configureClusterNameEnvRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How to configure Cluster Name and Environment during Onboarding
                using ChkkAgent?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={
            openAccordion === TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV
          }
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV : '',
            );
          }}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 border-r border-l border-b  border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4">
            <div className="flex w-full flex-col items-start gap-4">
              <span className="w-full text-body font-body text-default-font">
                During the onboarding process of your Kubernetes cluster with
                ChkkAgent, you have the flexibility to specify your Cluster Name
                and Cluster Environment directly through the Custom Resource
                Definition (CRD). This approach ensures a tailored and
                identifiable setup, aligning your cluster configuration with
                your operational requirements and naming conventions.{' '}
              </span>
              <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2">
                <span className="w-full text-body font-body text-default-font">
                  K8s Connector Requirements:
                </span>
                <div className="flex items-center gap-1 pl-4">
                  <SubframeCore.Icon
                    className="text-body font-body text-subtext-color"
                    name="FeatherCheck"
                  />
                  <span className="text-body font-body text-default-font">
                    {'K8s Connector: >= v0.1.11'}
                  </span>
                </div>
                <div className="flex items-center gap-1 pl-4">
                  <SubframeCore.Icon
                    className="text-body font-body text-subtext-color"
                    name="FeatherCheck"
                  />
                  <span className="text-body font-body text-default-font">
                    {'Chkk Operator: >= v0.0.9'}
                  </span>
                </div>
              </div>
              <span className="w-full text-body font-body text-default-font">
                Replace &lt;CLUSTER_NAME&gt;, and &lt;CLUSTER_ENVIRONMENT&gt;
                with your desired values. You can also set Cluster Name and
                Environment using CRDs with other configurations:
              </span>
              <div className="flex w-full flex-col items-start gap-4">
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none"
                  text={
                    'kubectl apply -f - <<EOF\napiVersion: k8s.chkk.io/v1beta1\nkind: ChkkAgent\nmetadata:\n   name: chkk-agent\n   namespace: chkk-system\nspec:\n  global:\n    clusterName: <CLUSTER_NAME>\n    clusterEnvironment: <CLUSTER_ENVIRONMENT>\nEOF'
                  }
                  singleLine={false}
                />
                <span className="w-full text-body font-body text-default-font">
                  Alternatively, you can edit your Cluster Name and Environment
                  in the Chkk Dashboard either by clicking the Edit button on
                  the cluster&#39;s card under Risk Ledger &gt; Clusters view OR
                  by clicking on the cluster card and then navigating to the
                  Properties tab.
                </span>
                <span className="text-body font-body text-default-font">
                  Note: If the Cluster Name and Environment are set from the IaC
                  then they cannot be edited from the Dashboard.
                </span>
              </div>
            </div>
          </div>
        </Accordion>
        {/*Problem - 7*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV_TERRAFORM}
              ref={configureClusterNameTerraformRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How do I set Cluster Name and Environment during Onboarding
                using the Terraform module?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={
            openAccordion ===
            TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV_TERRAFORM
          }
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen
                ? TroubleshootingProblems.CONFIGURE_CLUSTER_NAME_ENV_TERRAFORM
                : '',
            );
          }}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 border border-solid border-neutral-border px-4 py-4">
            <div className="flex w-full flex-col items-start gap-4">
              <span className="w-full text-body font-body text-default-font">
                During the onboarding process of your Kubernetes cluster with
                the Terraform module, you have the flexibility to specify your
                Cluster Name and Cluster Environment directly through the
                module. This approach ensures a tailored and identifiable setup,
                aligning your cluster configuration with your operational
                requirements and naming conventions.
              </span>
              <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2">
                <span className="w-full text-body font-body text-default-font">
                  Terraform module Requirements:
                </span>
                <div className="flex items-center gap-1 px-4">
                  <SubframeCore.Icon
                    className="text-body font-body text-subtext-color"
                    name="FeatherCheck"
                  />
                  <span className="text-body font-body text-default-font">
                    Chkk K8s Connector Terraform Module{' '}
                    {CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Text variant="body">
                  Before proceeding, select the Access Token from the dropdown
                  provided:
                </Text>
                <TokenSelector
                  label=""
                  placeholder="Select a Token"
                  helpText=""
                  value={selectedToken!}
                  tokens={activeTokens!}
                  onValueChange={(value: string) => {
                    setSelectedToken(value);
                  }}
                />
              </div>
              <span className="w-full text-body font-body text-default-font">
                Replace &lt;CLUSTER_NAME&gt;, and &lt;CLUSTER_ENVIRONMENT&gt;
                with your desired values. You can also set Cluster Name and
                Environment using CRDs with other configurations:
              </span>
              <div className="flex w-full flex-col items-start gap-4">
                {selectedToken ? (
                  <CopyToClipboard
                    language="hcl"
                    className="h-auto w-full flex-none sensitive select-none cursor-pointer"
                    text={`module "chkk_k8s_connector" {\n  source     = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"\n\n  create_namespace = true\n  namespace        = "chkk-system"\n\n  operator_config = {\n    secret = {\n      chkkAccessToken : ${selectedToken}\n    }\n  }\n\n  cluster_name = <CLUSTER_NAME>\n  cluster_environment = <CLUSTER_ENVIRONMENT>\n}`}
                    singleLine={false}
                  />
                ) : (
                  <CopyToClipboard
                    language="hcl"
                    className="h-auto w-full flex-none select-none cursor-pointer"
                    text={`module "chkk_k8s_connector" {\n  source     = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"\n\n  create_namespace = true\n  namespace        = "chkk-system"\n\n  operator_config = {\n    secret = {\n      chkkAccessToken : <ACCESS-TOKEN>\n    }\n  }\n\n  cluster_name = <CLUSTER_NAME>\n  cluster_environment = <CLUSTER_ENVIRONMENT>\n}`}
                    singleLine={false}
                  />
                )}
                <span className="w-full text-body font-body text-default-font">
                  Alternatively, you can edit your Cluster Name and Environment
                  in the Chkk Dashboard either by clicking the Edit button on
                  the cluster&#39;s card under Risk Ledger &gt; Clusters view OR
                  by clicking on the cluster card and then navigating to the
                  Properties tab.
                </span>
                <span className="text-body font-body text-default-font">
                  Note: If the Cluster Name and Environment are set from the IaC
                  then they cannot be edited from the Dashboard.
                </span>
              </div>
            </div>
          </div>
        </Accordion>
        {/*Problem - 8*/}
        <Accordion
          trigger={
            <>
              <div
                className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
                id={TroubleshootingProblems.RESOLVE_FAILED_TO_DETERMINE}
                ref={resolveFailedToDetermineRef}
              >
                <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                  How do I resolve the “failed to determine if *v1.ConfigMap is
                  namespaced: Forbidden” errors coming from ChkkAgent pods?
                </span>
                <Accordion.Chevron />
              </div>
              <CodeBlock className="h-auto w-full flex-none" language="shell">
                {`2024-06-26T18:19:47Z ERROR setup unable to start manager {"error": "failed to determine if *v1.ConfigMap is namespaced: failed to get restmapping: failed to get server groups: Get \\"https://172.20.0.1:443/api\\": Forbidden"}
main.main
    github.com/chkk-io/chkk-operator/cmd/manager/main.go:134
runtime.main
    runtime/proc.go:267`}
              </CodeBlock>
            </>
          }
          open={
            openAccordion ===
            TroubleshootingProblems.RESOLVE_FAILED_TO_DETERMINE
          }
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.RESOLVE_FAILED_TO_DETERMINE : '',
            );
          }}
        >
          <div className="flex w-full flex-col items-start border-r border-l border-b border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4">
            <div className="flex w-full flex-col items-start gap-4 pt-2.5">
              <span className="w-full text-body font-body text-default-font">
                This error is likely caused by your proxy server or firewall
                blocking requests to the kube-api server. To fix this issue, you
                need to allow your kube-api server address through the proxy
                server or firewall.
              </span>
            </div>
          </div>
        </Accordion>
        {/*Problem - 9*/}
        <Accordion
          trigger={
            <>
              <div
                className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
                id={TroubleshootingProblems.RESOLVE_GET_INGESTION}
                ref={resolveGetIngestionRef}
              >
                <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                  How do I resolve the “Get
                  https://ingestion.us-east-1.aws.chkk.io/v1/connector/k8s/config:
                  Forbidden” errors coming from ChkkAgent pods?
                </span>
                <Accordion.Chevron />
              </div>
              <CodeBlock className="h-auto w-full flex-none" language="shell">
                {`{
    "name": "chkk-agent",
    "namespace": "chkk-system"
},
"error": "Get \\"https://ingestion.us-east-1.aws.chkk.io/v1/connector/k8s/config?cloud_provider=AWS&internal_k8s_ref=353ffcce-b4cd-4e53-9726-54b54dc8583f&k8s_version=v1.28.9-eks-036c24b\\": Forbidden",
"method": "GET",
"url": "https://ingestion.us-east-1.aws.chkk.io/v1/connector/k8s/config?cloud_provider=AWS&internal_k8s_ref=353ffcce-b4cd-4e53-9726-54b54dc8583f&k8s_version=v1.28.9-eks-036c24b"`}
              </CodeBlock>
            </>
          }
          open={openAccordion === TroubleshootingProblems.RESOLVE_GET_INGESTION}
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.RESOLVE_GET_INGESTION : '',
            );
          }}
        >
          <div className="flex w-full flex-col items-start border-r border-l border-b border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4">
            <span className="w-full text-body font-body text-default-font">
              This error is likely caused by your proxy server or firewall
              blocking traffic to and from the “chkk.io” domain. The ChkkAgent
              needs to communicate with the Chkk API to sync the cluster state.
              To fix this issue, you need to allowlist the “chkk.io” domain and
              its subdomains in your proxy server or firewall.
            </span>
          </div>
        </Accordion>
        {/*Problem - 10*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.UNINSTALL_CHKK_OPERATOR}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How do I uninstall Chkk Operator in order to remove a Cluster?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={
            openAccordion === TroubleshootingProblems.UNINSTALL_CHKK_OPERATOR
          }
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.UNINSTALL_CHKK_OPERATOR : '',
            );
          }}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2  border-r border-l border-b  border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4">
            <div className="flex w-full flex-col items-start gap-4">
              <span
                className="w-full text-body font-body text-default-font"
                ref={uninstallChkkOperatorRef}
              >
                This guide provides step-by-step instructions to uninstall the
                Chkk Operator and remove all associated resources from your
                Kubernetes cluster.
              </span>
              <CodeBlock className="h-auto w-full flex-none">
                Deactivate the cluster first which you intend to remove.
              </CodeBlock>
              <span className="w-full text-body-bold font-body-bold text-default-font">
                Step 1: Remove Custom Resources
              </span>
              <span className="w-full text-body font-body text-default-font">
                The Chkk Operator manages ChkkAgent custom resources. Before
                deleting the operator, ensure that all instances of these custom
                resources are removed.
              </span>
              <div className="flex w-full flex-col items-start gap-4 pt-2 pr-2 pb-2 pl-2">
                <div className="flex w-full items-center gap-2">
                  <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                    List all ChkkAgent Custom Resources:
                  </span>
                  <CopyToClipboard
                    language="shell"
                    className="h-auto grow shrink-0 basis-0"
                    text="kubectl get chkkagent --all-namespaces"
                  />
                </div>
                <div className="flex w-full items-center gap-2">
                  <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                    Delete all ChkkAgent Custom Resources:
                  </span>
                  <CopyToClipboard
                    language="shell"
                    className="h-auto grow shrink-0 basis-0"
                    text="kubectl delete chkkagent --all --all-namespaces"
                  />
                </div>
              </div>
              <span className="w-full text-body-bold font-body-bold text-default-font">
                Step 2: Remove Chkk Operator
              </span>
              <span className="w-full text-body font-body text-default-font">
                Once all custom resources are deleted, you can remove the Chkk
                Operator.
              </span>
              <TabsWithContent
                initialTabId={installMethod || undefined}
                onTabChange={(newTabId) => {
                  setInstallMethod(newTabId);
                }}
                tabs={[
                  {
                    id: 'default',
                    title: 'Helm',
                    content: (
                      <div className="flex w-full flex-col items-start gap-4 pt-2 pr-2 pb-2 pl-2">
                        <div className="flex w-full items-center gap-2">
                          <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                            Get the Chkk Operator chart details:
                          </span>
                          <CopyToClipboard
                            language="shell"
                            className="h-auto grow shrink-0 basis-0"
                            text="helm ls -n chkk-system"
                          />
                        </div>
                        <div className="flex w-full items-center gap-2">
                          <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                            Uninstall the Chkk Operator helm chart:
                          </span>
                          <CopyToClipboard
                            language="shell"
                            className="h-auto grow shrink-0 basis-0"
                            text="helm uninstall chkk-operator -n chkk-system"
                          />
                        </div>
                        <div className="flex w-full items-center gap-2">
                          <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                            Delete the chkk-system namespace:
                          </span>
                          <CopyToClipboard
                            language="shell"
                            className="h-auto grow shrink-0 basis-0"
                            text="kubectl delete ns chkk-system"
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'yaml-based-install',
                    title: 'K8s YAML',
                    content: (
                      <div className="flex w-full flex-col items-start gap-4 pt-2 pr-2 pb-2 pl-2">
                        <div className="flex w-full items-center gap-2">
                          <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                            List all resources in chkk-system namespace:
                          </span>
                          <CopyToClipboard
                            language="shell"
                            className="h-auto grow shrink-0 basis-0"
                            text="kubectl get all -n chkk-system"
                          />
                        </div>
                        <div className="flex w-full items-center gap-2">
                          <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                            Delete Chkk Operator resources:
                          </span>
                          <CopyToClipboard
                            language="shell"
                            className="h-auto grow shrink-0 basis-0"
                            text="kubectl delete -f https://helm.chkk.io/chkk-operator/manifest.yaml"
                          />
                        </div>
                        <div className="flex w-full items-center gap-2">
                          <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                            Delete the chkk-system namespace:
                          </span>
                          <CopyToClipboard
                            language="shell"
                            className="h-auto grow shrink-0 basis-0"
                            text="kubectl delete ns chkk-system"
                          />
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
              <span className="w-full text-[14px] font-[400] leading-[20px] text-default-font">
                Note: If you installed the Chkk Operator with custom
                ServiceAccounts, Roles, RoleBindings, or Secrets; make sure you
                also remove them as part of the uninstallation process.
              </span>
              <span className="w-full text-body-bold font-body-bold text-default-font">
                Step 3: Remove ChkkAgent CRDs
              </span>
              <span className="w-full text-[14px] font-[400] leading-[20px] text-default-font">
                Once all the Chkk Operator resources have been removed, you can
                complete the uninstallation process by removing the ChkkAgent
                CRDs.
              </span>
              <div className="flex w-full items-center gap-2 pt-2 pr-2 pb-2 pl-2">
                <span className="w-72 flex-none text-body-bold font-body-bold text-default-font">
                  Remove the CRDs:
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto grow shrink-0 basis-0"
                  text="kubectl delete crds chkkagents.k8s.chkk.io"
                />
              </div>
            </div>
          </div>
        </Accordion>
        {/*Problem - 11*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              ref={activateDeactivateClusterRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How do I activate a deactivated cluster?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={
            openAccordion ===
            TroubleshootingProblems.ACTIVATE_CLUSTER_INSTRUCTIONS
          }
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen
                ? TroubleshootingProblems.ACTIVATE_CLUSTER_INSTRUCTIONS
                : '',
            );
          }}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 border border-solid border-neutral-border pt-4 pr-4 pb-4 pl-4">
            <div className="flex w-full flex-col items-start gap-4">
              <span className="w-full text-body font-body text-default-font">
                To reactivate a deactivated cluster in your system, follow these
                steps:
              </span>
              <span className="w-full text-body font-body text-default-font">
                1/ Navigate to Configure &gt; Settings &gt; Clusters &gt;
                Deactivated Clusters tab.
              </span>
              <span className="w-full text-body font-body text-default-font">
                2/ Search for the cluster you want to activate and click
                Activate Cluster.
              </span>
              <span className="w-full text-body font-body text-default-font">
                3/ The activated cluster will now appear in the Risk Ledger,
                Artifact Register and can be used in the Upgrade Copilot.
              </span>
            </div>
          </div>
        </Accordion>
        {/*Problem - 12*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.OVERRIDE_CHKK_IMAGES}
              ref={overrideChkkImagesRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How to override Default Images during Cluster Onboarding?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={openAccordion === TroubleshootingProblems.OVERRIDE_CHKK_IMAGES}
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.OVERRIDE_CHKK_IMAGES : '',
            );
          }}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 border border-solid border-neutral-border px-4 py-4">
            <div className="flex w-full flex-col items-start gap-4">
              <span className="w-full text-body font-body text-default-font">
                The Chkk Operator and ChkkAgent CRD allow you to override
                default container images.
              </span>
              <div className="flex w-full flex-col items-start gap-2">
                <span className="text-body-bold font-body-bold text-default-font">
                  Default Images:
                </span>
                <div className="flex items-center gap-2">
                  <span className="text-body-bold font-body-bold text-default-font">
                    - Chkk Operator:
                  </span>
                  <span className="text-body font-body text-default-font">
                    public.ecr.aws/chkk/operator:{CHKK_OPERATOR_VERSION}
                  </span>
                </div>
                <span className="text-body-bold font-body-bold text-default-font">
                  - ChkkAgent:
                </span>
                <ul className="flex flex-col items-start gap-2 px-8 list-disc">
                  <li className="text-body font-body text-default-font list-disc">
                    Agent Manager Image:
                    public.ecr.aws/chkk/cluster-agent-manager:
                    {CHKK_CLUSTER_AGENT_VERSION}
                  </li>
                  <li className="text-body font-body text-default-font">
                    Agent Image: public.ecr.aws/chkk/cluster-agent:
                    {CHKK_CLUSTER_AGENT_VERSION}
                  </li>
                </ul>
              </div>
              <div className="flex w-full flex-col items-start gap-2 px-1 py-1">
                <span className="w-full text-body-bold font-body-bold text-default-font">
                  Step 1: Create Namespace
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none"
                  text="kubectl create ns chkk-system"
                />
              </div>
              <div className="flex w-full flex-col items-start gap-2 px-1 py-1">
                <span className="w-full text-body-bold font-body-bold text-default-font">
                  Step 2: Add the Chkk Helm Repository
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none"
                  text="helm repo add chkk https://helm.chkk.io"
                />
                <span className="text-body font-body text-default-font">
                  If you already have the Chkk Helm repository added, update it
                  using the following command:
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none"
                  text="helm repo update chkk"
                />
              </div>
              <div className="flex w-full flex-col items-start gap-2 px-1 py-1">
                <span className="w-full text-body-bold font-body-bold text-default-font">
                  Step 3: Deploy Chkk Operator with a Custom Image
                </span>
                <div className="flex items-center gap-2">
                  <Text variant="body">
                    Before proceeding, select the Access Token from the dropdown
                    provided:
                  </Text>
                  <TokenSelector
                    label=""
                    placeholder="Select a Token"
                    helpText=""
                    value={selectedToken!}
                    tokens={activeTokens!}
                    onValueChange={(value: string) => {
                      setSelectedToken(value);
                    }}
                  />
                </div>

                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none sensitive select-none cursor-pointer"
                  text={[
                    'helm install chkk-operator chkk/chkk-operator \\',
                    '  --namespace chkk-system \\',
                    '  --set secret.create=true \\',
                    `  --set secret.chkkAccessToken=${
                      selectedToken || '<ACCESS-TOKEN>'
                    } \\`,
                    '  --set image.repository=<REPOSITORY_URL> \\',
                    '  --set image.tag=<TAG>',
                  ].join('\n')}
                />
              </div>
              <div className="flex w-full flex-col items-start gap-2 px-1 py-1">
                <span className="w-full text-body-bold font-body-bold text-default-font">
                  Step 4: Create a ChkkAgent Resource with Custom Images
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none"
                  text={[
                    'kubectl apply -f - <<EOF',
                    'apiVersion: k8s.chkk.io/v1beta1',
                    'kind: ChkkAgent',
                    'metadata:',
                    '  name: chkk-agent',
                    '  namespace: chkk-system',
                    'spec:',
                    '  agentOverride:',
                    '    image:',
                    '      name: <AGENT_IMAGE_URL>',
                    '    managerImage:',
                    '      name: <AGENT_MANAGER_IMAGE_URL>',
                    'EOF',
                  ].join('\n')}
                />
              </div>
            </div>
          </div>
        </Accordion>
        {/*Problem - 13*/}
        <Accordion
          trigger={
            <div
              className="flex w-full items-center gap-2 pt-2 pr-3 pb-2"
              id={TroubleshootingProblems.UPGRADE_K8S_CONNECTOR}
              ref={upgradeK8sConnectorRef}
            >
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                How can I upgrade to a supported version of the K8s Connector?
              </span>
              <Accordion.Chevron />
            </div>
          }
          open={openAccordion === TroubleshootingProblems.UPGRADE_K8S_CONNECTOR}
          onOpenChange={(isOpen) => {
            handleOpen(
              isOpen ? TroubleshootingProblems.UPGRADE_K8S_CONNECTOR : '',
            );
          }}
        >
          <TroubleShootUpgradeK8sConnectorFAQ tokens={tokens} />
        </Accordion>
      </div>
    </Page>
  );
}

export default TroubleshootingK8SConnector;
